import React from 'react'

const SyncNotesButton = () => {
    return (
      <span class="italic inline-block">
        This Note no longer<br />supports syncing
      </span>
    )
  }

export default SyncNotesButton
